import React, { FC, useState } from 'react';
import { GrafanaTheme } from '@grafana/data';
import { Button, Checkbox, Field, HorizontalGroup, Input, Modal, stylesFactory, useTheme } from '@grafana/ui';
import { css } from 'emotion';

import { validateMultipleEmails } from '../utils/validators';

interface Props {
  reportId: number;
  emails: string;
  onDismiss: () => void;
  onSendTestMail: (email: string, reportId: number, useEmailsFromReport: boolean) => void;
}

export const SendTestMailModal: FC<Props> = ({ onDismiss, onSendTestMail, reportId, emails }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [email, setEmail] = useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [emailIsInvalid, setEmailIsInvalid] = useState<boolean>(false);
  const [useEmailsFromReport, setUseEmailsFromReport] = useState<boolean>(false);

  const handleUseEmailsFromReportChange = (event: any) => {
    setUseEmailsFromReport(event.target.checked);
    if (event.target.checked) {
      setEmail(emails);
      doValidation(emails);
    } else {
      clearError();
      setEmail('');
    }
  };

  const handleEmailChange = (e: string) => {
    setEmail(e);
    setEmailIsInvalid(false);
  };

  const doValidation = (e: string): boolean => {
    if (!validateMultipleEmails(e)) {
      setEmailIsInvalid(true);
      setEmailErrorMessage('Invalid email');
      return false;
    } else {
      clearError();
      return true;
    }
  };

  const clearError = () => {
    setEmailIsInvalid(false);
    setEmailErrorMessage('');
  };

  const sendMail = () => {
    doValidation(email);
    if (!doValidation(email)) {
      return;
    }
    onSendTestMail(email, reportId, useEmailsFromReport);
    onDismiss();
  };

  return (
    <Modal className={styles.modal} isOpen={true} title="Send test email" icon="share-alt" onDismiss={onDismiss}>
      <>
        <div className={styles.content}>
          <Field label="Email" disabled={useEmailsFromReport} invalid={emailIsInvalid} error={emailErrorMessage}>
            <Input
              disabled={useEmailsFromReport}
              name="email"
              placeholder="your.address@company.com"
              type="email"
              value={email}
              onChange={e => handleEmailChange(e.currentTarget.value)}
            />
          </Field>
          <Checkbox
            value={useEmailsFromReport}
            label="Use emails from report"
            onChange={handleUseEmailsFromReportChange}
          />
        </div>

        <HorizontalGroup justify="center">
          <Button variant="primary" onClick={sendMail}>
            Send
          </Button>
          <Button variant="secondary" onClick={onDismiss}>
            Cancel
          </Button>
        </HorizontalGroup>
      </>
    </Modal>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    modal: css`
      width: 500px;
    `,
    content: css`
      margin-bottom: ${theme.spacing.lg};
    `,
  };
});
