import { getBackendSrv } from '@grafana/runtime';
import { dateTime } from '@grafana/data';

export const DAILY_SUMMARY_DATE_FORMAT = 'YYYY-MM-DD';

export interface RecentUser {
  id: number;
  name?: string;
  avatarUrl: string;
  login: string;
  email?: string;
}

export interface UserViewDTO {
  user: RecentUser;
  viewed: string;
}

export interface DashboardDailySummaryDTO {
  day: string;
  dashboardId: number;
  views: number;
  queries: number;
  errors: number;
  loadDuration: number;
}

export const getRecentUsers = async (dashboardId: number): Promise<UserViewDTO[]> => {
  return await getBackendSrv().get(`/api/usage/dashboard/${dashboardId}/views/recent`);
};

export const getUserViews = async (dashboardId: number, limit: number): Promise<UserViewDTO[]> => {
  return await getBackendSrv().get(`/api/usage/dashboard/${dashboardId}/views?limit=${limit}`);
};

export const getDailySummaries = async (dashboardId: number, days: string[]): Promise<DashboardDailySummaryDTO[]> => {
  const dailySummaries = await getBackendSrv().get(`/api/usage/dashboard/${dashboardId}/daily`, {
    days,
  });
  const summariesArray: DashboardDailySummaryDTO[] = [];
  days.map(day => {
    if (dailySummaries[day]) {
      summariesArray.push(dailySummaries[day]);
    }
  });
  summariesArray.sort(
    (a, b) =>
      dateTime(a.day, DAILY_SUMMARY_DATE_FORMAT).valueOf() - dateTime(b.day, DAILY_SUMMARY_DATE_FORMAT).valueOf()
  );

  return summariesArray;
};
