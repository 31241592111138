import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { css, cx } from 'emotion';
import isEqual from 'lodash/isEqual';
import { GrafanaTheme } from '@grafana/data';
import { Button, stylesFactory, Tooltip, useTheme } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { addCustomLeftAction } from 'app/features/dashboard/components/DashNav/DashNav';
import { DashboardModel } from 'app/features/dashboard/state';
import { AnalyticsTab } from '../types';
import { getRecentUsers, RecentUser, UserViewDTO } from './api';
import { openDrawer } from './state/actions';

const getPresenceIndicatorsStyles = stylesFactory((theme: GrafanaTheme, tooManyUsers: boolean) => {
  return {
    container: css`
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin-left: ${theme.spacing.sm};
    `,
    icon: css`
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: -6px;
      border: 3px ${theme.colors.dashboardBg} solid;
      box-shadow: 0 0 0 1px ${theme.colors.pageHeaderBorder};
    `,
    numberIcon: css`
      padding: 0px;
      text-align: center;
      line-height: 26px;
      justify-content: center;
      color: ${theme.colors.textSemiWeak};
      span {
        margin-bottom: ${tooManyUsers ? '3px' : '0px'};
      }
    `,
  };
});

export interface PresenceIndicatorsProps {
  dashboard: DashboardModel;
  openDrawer: typeof openDrawer;
}

const iconLimit = 4;
const refreshInterval = 60000; // In milliseconds

function fetchRecentUsers(dashboardId: number, setRecentUsers: React.Dispatch<React.SetStateAction<RecentUser[]>>) {
  const user = contextSrv.user;
  getRecentUsers(dashboardId).then(data => {
    const items = data.map((item: UserViewDTO) => item.user).filter((item: RecentUser) => item.id !== user.id);
    setRecentUsers((recentUsers: RecentUser[]) => (isEqual(items, recentUsers) ? recentUsers : items));
  });
}

export const PresenceIndicators: FC<PresenceIndicatorsProps> = ({ dashboard, openDrawer }) => {
  const dashboardId = dashboard.id;
  const [recentUsers, setRecentUsers] = useState<RecentUser[]>([]);

  const nbOfUsers = recentUsers.length - iconLimit + 1;
  const tooManyUsers = nbOfUsers > 9;

  const theme = useTheme();
  const styles = getPresenceIndicatorsStyles(theme, tooManyUsers);

  useEffect(() => {
    if (!dashboardId) {
      return undefined;
    }

    fetchRecentUsers(dashboardId, setRecentUsers);
    const interval = setInterval(() => fetchRecentUsers(dashboardId, setRecentUsers), refreshInterval);
    return () => {
      clearInterval(interval);
    };
  }, [dashboardId]);

  const iconLimitReached = recentUsers.length > iconLimit;
  return (
    <>
      {recentUsers.length > 0 && (
        <div className={styles.container} aria-label="Presence indicators container">
          {recentUsers.slice(0, iconLimitReached ? iconLimit - 1 : iconLimit).map(user => (
            <Tooltip content={user.login} key={`recent-user-${user.id}`}>
              <img className={styles.icon} src={user.avatarUrl} aria-label="Avatar icon" alt={`${user.login} avatar`} />
            </Tooltip>
          ))}
          {iconLimitReached && (
            <Button
              variant="secondary"
              className={cx(styles.numberIcon, styles.icon)}
              aria-label="More users icon"
              onClick={() => openDrawer(AnalyticsTab.Users)}
            >
              {tooManyUsers ? '...' : `+${nbOfUsers}`}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

const mapActionsToProps = {
  openDrawer,
};

export const initPresenceIndicators = () => {
  addCustomLeftAction({
    show: () => true,
    component: connect(null, mapActionsToProps)(PresenceIndicators),
    index: 'end',
  });
};
