import React from 'react';
import { css } from 'emotion';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ArrayVector,
  FieldType,
  DataFrame,
  DateTime,
  dateTime,
  GrafanaTheme,
  applyFieldOverrides,
} from '@grafana/data';
import { Table, Themeable, stylesFactory, withTheme } from '@grafana/ui';
import { DashboardModel } from 'app/features/dashboard/state';
import { AnalyticsTab } from './AnalyticsTab';
import { DashboardDailySummaryDTO, UserViewDTO } from '../api';

interface Props extends Themeable {
  dashboard: DashboardModel;
  dailySummaries: DashboardDailySummaryDTO[];
  userViews: UserViewDTO[];
}

const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  boxContainer: css`
    display: flex;
    margin-top: ${theme.spacing.lg};
  `,
  box: css`
    margin: ${theme.spacing.xs};
    padding: ${theme.spacing.sm};
    flex: 1;
    text-align: center;
    background-color: ${theme.colors.dashboardBg};
  `,
  userName: css`
    font-weight: ${theme.typography.weight.bold};
  `,
  tableTitle: css`
    margin-top: ${theme.spacing.lg};
    margin-bottom: ${theme.spacing.md};
  `,
}));

class AnalyticsUsersTab extends AnalyticsTab<Props> {
  formatDate(date: DateTime): string {
    const diffDays = date.diff(dateTime().startOf('day'), 'days', true);

    if (diffDays < -6) {
      return date.format('YYYY-MM-DD');
    } else if (diffDays < -1) {
      return date.locale('en').format('dddd');
    } else if (diffDays < 0) {
      return 'Yesterday';
    } else {
      const diffMinutes = date.diff(dateTime(), 'minutes', true);

      if (diffMinutes < -60) {
        return 'Today';
      } else if (diffMinutes < -15) {
        return 'Last hour';
      } else {
        return 'Currently viewing';
      }
    }
  }

  convertUserViewsToDataFrame(): DataFrame {
    const { userViews } = this.props;
    if (userViews && userViews.length > 0) {
      const time = new ArrayVector<string>([]);
      const users = new ArrayVector<string>([]);

      userViews.map(userView => {
        time.buffer.push(this.formatDate(dateTime(userView.viewed)));
        users.buffer.push(userView.user.login);
      });

      const data = {
        name: name,
        fields: [
          { name: 'User', type: FieldType.string, config: {}, values: users },
          { name: 'When', type: FieldType.string, config: {}, values: time },
        ],
        length: userViews.length,
      };

      return applyFieldOverrides({
        data: [data],
        fieldConfig: {
          overrides: [],
          defaults: {},
        },
        theme: this.props.theme,
        replaceVariables: (value: string) => value,
      })[0];
    } else {
      return null;
    }
  }

  renderUserBox(title: string, userName: string, date: string) {
    const { theme } = this.props;
    const styles = getStyles(theme);

    return (
      <div className={styles.box}>
        <h6>{title}</h6>
        <div className={styles.userName}>{userName}</div>
        <div>{dateTime(date).format('YYYY-MM-DD')}</div>
      </div>
    );
  }

  renderViewsChart() {
    const timeRange = this.buildTimeRange();

    return (
      <AutoSizer disableHeight>
        {({ width }) => {
          if (width === 0) {
            return null;
          }

          return (
            <main style={{ width }}>
              {this.renderChart({
                title: 'Views last 30 days',
                valueField: 'views',
                fieldType: FieldType.number,
                width,
                timeRange,
                color: '',
                showBars: true,
                showLines: false,
              })}
            </main>
          );
        }}
      </AutoSizer>
    );
  }

  render() {
    const { dailySummaries, dashboard, theme, userViews } = this.props;
    const styles = getStyles(theme);

    const userViewsDataFrame = this.convertUserViewsToDataFrame();
    return (
      <div>
        {dailySummaries && dailySummaries.length > 0 && this.renderViewsChart()}
        <div className={styles.boxContainer}>
          {dashboard.meta.created && this.renderUserBox('Created', dashboard.meta.createdBy, dashboard.meta.created)}
          {dashboard.meta.updated &&
            this.renderUserBox('Last edited', dashboard.meta.updatedBy, dashboard.meta.updated)}
          {userViews &&
            userViews.length > 0 &&
            this.renderUserBox('Last viewed', userViews[0].user.login, userViews[0].viewed)}
        </div>
        {userViewsDataFrame && (
          <div>
            <h4 className={styles.tableTitle}>Recent users of this dashboard</h4>
            <AutoSizer disableHeight>
              {({ width }) => {
                if (width === 0) {
                  return null;
                }

                return (
                  <main style={{ width }}>
                    <Table data={userViewsDataFrame} height={400} width={width} />
                  </main>
                );
              }}
            </AutoSizer>
          </div>
        )}
      </div>
    );
  }
}

export default withTheme(AnalyticsUsersTab);
