import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { DateTime, NavModel, SelectableValue, urlUtil } from '@grafana/data';
import { Button, LinkButton, Input, TextArea, Form, Field, InputControl, ModalsController } from '@grafana/ui';

import { DashboardPicker } from 'app/core/components/Select/DashboardPicker';
import Page from 'app/core/components/Page/Page';
import { getRouteParamsId } from 'app/core/selectors/location';
import { getNavModel } from 'app/core/selectors/navModel';
import { ReportScheduling } from './ReportScheduling';
import { ReportOptionsPicker } from './ReportOptionsPicker';
import { createReport, loadReport, sendTestMail, updateReport } from './state/actions';
import { EnterpriseStoreState, ReportOptions, ReportDTO, Report, SchedulingFrequency } from '../types';
import { validateMultipleEmails } from '../utils/validators';
import { config } from 'app/core/config';
import { SendTestMailModal } from './SendTestMailModal';
import { clearReportState, updateReportProp } from './state/reducers';

export interface Props {
  report: Report;
  navModel: NavModel;
  reportId?: number;
  isLoading: boolean;
  loadReport: typeof loadReport;
  clearReportState: typeof clearReportState;
  updateReport: typeof updateReport;
  createReport: typeof createReport;
  updateReportProp: typeof updateReportProp;
  sendTestMail: typeof sendTestMail;
}

export class ReportPage extends PureComponent<Props> {
  componentDidMount() {
    const { loadReport, reportId } = this.props;
    if (reportId) {
      loadReport(reportId);
    }
  }

  componentWillUnmount() {
    this.props.clearReportState();
  }

  onModeChange = (frequency: SchedulingFrequency) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        frequency,
      },
    });
  };

  onDayOfWeekChange = (dayOfWeek: SelectableValue<string>) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        day: dayOfWeek.value as string,
      },
    });
  };

  onTimeOfDayChange = (timeOfDay: DateTime) => {
    const { report, updateReportProp } = this.props;
    if (timeOfDay.hour && timeOfDay.minute) {
      updateReportProp({
        ...report,
        schedule: {
          ...report.schedule,
          hour: timeOfDay.hour(),
          minute: timeOfDay.minute(),
        },
      });
    }
  };

  onTimeZoneChange = (timeZone: string) => {
    const { report, updateReportProp } = this.props;

    updateReportProp({
      ...report,
      schedule: {
        ...report.schedule,
        timeZone,
      },
    });
  };

  onDashboardChange = (dashboard: SelectableValue<number>) => {
    const { report, updateReportProp } = this.props;
    if (dashboard) {
      updateReportProp({
        ...report,
        dashboardId: dashboard.id,
        dashboardName: dashboard.label as string,
      });
    } else {
      updateReportProp({
        ...report,
        dashboardId: 0,
        dashboardName: '',
      });
    }
  };

  onOptionsChange = (options: ReportOptions) => {
    const { report, updateReportProp } = this.props;
    updateReportProp({
      ...report,
      options,
    });
  };

  submitForm = (reportData: ReportDTO) => {
    const { createReport, updateReport, report, reportId } = this.props;
    const { schedule, options, dashboardId } = report;
    const { name, replyTo, recipients, message } = reportData;

    const createOrUpdate = reportId ? updateReport : createReport;

    const reportDto: ReportDTO = {
      id: report ? report.id : undefined,
      name,
      recipients,
      dashboardId,
      replyTo,
      message,
      schedule,
      options,
    };

    createOrUpdate(reportDto);
  };

  getPreviewUrl() {
    const { report } = this.props;
    const { name, dashboardId, options } = report;

    if (!dashboardId) {
      return undefined;
    }

    const params: any = {
      title: name,
    };

    if (options.landscape) {
      // Use string param because macaron can't handle
      // bool param without value like http://grafana/api/render?landscape
      params.landscape = `${options.landscape}`;
    }

    return urlUtil.appendQueryToUrl(`api/reports/render/pdf/${dashboardId}`, urlUtil.toUrlParams(params));
  }

  render() {
    const { navModel, report, reportId, isLoading } = this.props;
    const { message, name, recipients, replyTo, schedule, dashboardId, dashboardName, options } = report;
    const heading = reportId ? `Edit ${name}` : 'New report';
    const dashboardSelected = dashboardId > 0;
    const currentDashboard = dashboardSelected ? { value: dashboardId, label: dashboardName } : undefined;
    const previewUrl = this.getPreviewUrl();

    return (
      <Page navModel={navModel}>
        <Page.Contents isLoading={Boolean(isLoading && reportId)}>
          <h3 className="page-sub-heading">{heading}</h3>
          <Form onSubmit={this.submitForm} validateOn="onBlur">
            {({ register, errors, control }) => {
              return (
                <>
                  <Field label="Name" required invalid={!!errors.name} error="Name is required">
                    <Input
                      defaultValue={name}
                      name="name"
                      ref={register({ required: true })}
                      placeholder="System status report"
                    />
                  </Field>
                  <Field label="Choose dashboard" required invalid={!!errors.dashboardId} error="Dashboard is required">
                    <InputControl
                      name="dashboardId"
                      control={control}
                      as={DashboardPicker}
                      onSelected={(dashboard: SelectableValue) => {
                        this.onDashboardChange(dashboard);
                        // We need to manually set the form value for the form to trigger validation on change
                        control.setValue('dashboardId', dashboard?.id, true);
                      }}
                      defaultValue={currentDashboard?.value}
                      currentDashboard={currentDashboard}
                      rules={{ required: true }}
                      isClearable
                    />
                  </Field>
                  <Field label="Recipients" required invalid={!!errors.recipients} error={errors.recipients?.message}>
                    <TextArea
                      name="recipients"
                      ref={register({
                        required: 'Recipients are required',
                        validate: val => validateMultipleEmails(val) || 'Invalid email',
                      })}
                      placeholder="name@company.com;another.name@company.com"
                      defaultValue={recipients}
                    />
                  </Field>
                  <Field label="Reply to">
                    <Input
                      name="replyTo"
                      ref={register}
                      placeholder="your.address@company.com - optional"
                      type="email"
                      defaultValue={replyTo}
                    />
                  </Field>
                  <Field label="Custom message">
                    <TextArea name="message" placeholder={message} rows={10} ref={register} defaultValue={message} />
                  </Field>

                  <h4>Options</h4>
                  <ReportOptionsPicker options={options} onChange={this.onOptionsChange} />

                  <h4>Scheduling</h4>
                  <ReportScheduling
                    schedulingOptions={schedule}
                    onModeChange={this.onModeChange}
                    onDayOfWeekChange={this.onDayOfWeekChange}
                    onTimeOfDayChange={this.onTimeOfDayChange}
                    onTimeZoneChange={this.onTimeZoneChange}
                  />

                  <div
                    className={css`
                      margin-top: 40px;
                    `}
                  >
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className={css`
                        margin-right: 15px;
                      `}
                    >
                      Save
                    </Button>

                    <LinkButton
                      href={previewUrl}
                      size="xs"
                      target="_blank"
                      variant="secondary"
                      disabled={!config.rendererAvailable}
                      className={css`
                        margin-right: 15px;
                      `}
                    >
                      Preview
                    </LinkButton>

                    <ModalsController>
                      {({ showModal, hideModal }) => (
                        <Button
                          size="xs"
                          variant="secondary"
                          onClick={e => {
                            showModal(SendTestMailModal, {
                              onDismiss: hideModal,
                              onSendTestMail: sendTestMail,
                              reportId: reportId,
                              emails: recipients,
                            });
                            e.preventDefault();
                          }}
                        >
                          Send test mail
                        </Button>
                      )}
                    </ModalsController>
                  </div>
                </>
              );
            }}
          </Form>
        </Page.Contents>
      </Page>
    );
  }
}

function mapStateToProps(state: EnterpriseStoreState) {
  const reportId = getRouteParamsId(state.location);
  return {
    navModel: getNavModel(state.navIndex, 'reports-list'),
    report: state.reports.report,
    isLoading: state.reports.isLoading,
    reportId,
  };
}

const mapActionsToProps = {
  updateReport,
  loadReport,
  createReport,
  clearReportState,
  updateReportProp,
  sendTestMail,
};

export default connect(mapStateToProps, mapActionsToProps)(ReportPage);
