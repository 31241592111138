import { getBackendSrv } from '@grafana/runtime';
import { updateLocation } from 'app/core/actions';
import { ThunkResult } from 'app/types';
import { ReportDTO } from '../../types';
import { reportLoaded, reportsLoaded, reportLoadingBegin, reportLoadingEnd } from './reducers';
import appEvents from 'app/core/app_events';
import { AppEvents } from '@grafana/data';

export function getReports(): ThunkResult<void> {
  return async dispatch => {
    const reports = await getBackendSrv().get('/api/reports');
    dispatch(reportsLoaded(reports));
  };
}

export function loadReport(id: number): ThunkResult<void> {
  return async dispatch => {
    dispatch(reportLoadingBegin());
    try {
      const report = await getBackendSrv().get(`/api/reports/${id}`);
      dispatch(reportLoaded(report));
    } catch (e) {
      dispatch(reportLoadingEnd());
    }
  };
}

export function sendTestMail(recipients: string, reportId: number, useEmailsFromReport: boolean): void {
  try {
    getBackendSrv().post(`/api/reports/email/`, {
      email: recipients,
      id: reportId,
      useEmailsFromReport: useEmailsFromReport,
    });
    appEvents.emit(AppEvents.alertSuccess, ['Send test mail', 'Email sent']);
  } catch (error) {
    appEvents.emit(AppEvents.alertError, ['Send test mail', 'Failed sending test mail']);
  }
}

export function deleteReport(id: number): ThunkResult<void> {
  return async dispatch => {
    await getBackendSrv().delete(`/api/reports/${id}`);
    dispatch(getReports());
  };
}

export function createReport(report: ReportDTO): ThunkResult<void> {
  return async dispatch => {
    try {
      await getBackendSrv().post('/api/reports', report);
    } catch (error) {
      throw error;
    }
    dispatch(getReports());
    dispatch(updateLocation({ path: '/reports' }));
  };
}

export function updateReport(report: ReportDTO): ThunkResult<void> {
  return async dispatch => {
    await getBackendSrv().put(`/api/reports/${report.id}`, report);
    dispatch(getReports());
    dispatch(updateLocation({ path: '/reports' }));
  };
}

export const setSearchQuery = (value: string) => {};
